.myprofile-page{
    .mt-10{
        margin-top: 10px;
    }
    .mt-17{margin-top: 17px;}
    .readonly-bg input{background: #f9f9f9;}
    letter-spacing: 0.5px;
    .myproflile-detail{
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
        div{
            width: 98%;
           
        }
      
    }
    .btn-profilepage{
        padding: 1.5px 10px;
        border: 1px solid #000;
        background: #fff;
        color: #000;
        white-space: nowrap;
        font-weight: 400;
        span{
            margin-right: 4px;
        }
    }
    .mr-03{
        margin-right: 0.5rem;
    }
    .w-100{width: 100%;}
    .line-height-20{
        line-height: 20px;
       
    }
}