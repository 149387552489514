.calendar-wrapper{
    height: 60vh;
    overflow-y: scroll;
    &.contact, &.step3{        
        overflow-y: hidden;
    }
    .scroll-div{
        overflow: scroll;
        height: calc(100% - 30px);
    }
    margin-left: 15px;
    .button-wrapper{
        display: flex;
        margin-top: 15px;
    }
    .text-field-space{
        margin-bottom: 15px;
    }
    .grid-space{
        margin-top: 30px;
    }
    .calendar-menu {
        .MuiPaper-root {
        border-radius: 6px;
        margin-top: 8px; 
        min-width: 180px;
        color: rgb(55, 65, 81);
        box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
                    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
                    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
                    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    
        .MuiMenu-list {
            padding: 4px 0;
        }
    
        .MuiMenuItem-root {
            .MuiSvgIcon-root {
            font-size: 18px;
            color: #6e6e6e; 
            margin-right: 12px; 
            }
            &:active {
            background-color: rgba(25, 118, 210, 0.08); 
            }
        }
    
        &.dark {
            color: #d1d1d1; 
        }
        }
    }
}