.login-waper{
    width:400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin-top: -35px;
  }
  .login-logo{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top:15px;
    padding-bottom: 15px;
    
    img{
        height: 70px;
        width: auto;
    }
  }
  .login-container {
    width:100%;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(158, 158, 158, 0.25);
    border: 1px solid #ccc;
    padding: 30px;
    position: relative;

    .login-loader{
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      justify-content: center;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 20px;
      bottom: 0;
      right: 0;
      align-items: center;
      background: rgb(255 255 255 / 15%);
    }
  }
 
  .login-container h1{
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin: 0px 0px 10px 0px;
    padding: 0;
  }
  .login-container p{
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    margin: 0px 0px 15px 0px;
    padding: 0;
  }
  .login-form{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .login-form .loginemail,  .login-form .loginpassword{
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
  .login-form .loginemail input,  .login-form .loginpassword input{
    font-size: 14px;
    font-weight: 400;
  }
  .login-form label{
    font-size: 14px;
    font-weight: 400;
 
  }
  .login-form button{
    width: 100%;
    margin: 0px 0px 10px 0px;
   
  }
  .forget-password-link {
    text-align: center;
    width: 100%;
  }
  .forget-password-link a{
    font-size: 14px;
    color: $primary_blue;
    text-decoration: underline;
    font-weight: 500;
   
  }
  .forget-password-link a:hover{
    text-decoration: none;
  }
  .recaptcha{
    transform:scale(1.13);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    margin: 0px 0px 20px 0px;
  }
  .invalid-user-password{
    width: 100%;
    text-align: center;
    color: red;

  }

  .build-number {
    text-align: center;
    width: 100%;
    margin-top: 5px;;
    font-size: 0.7rem;
    background: #fff;
  }